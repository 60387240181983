import React, {useState, useEffect} from 'react'
import Seo from "../components/molecules/Seo"
import styled, { withTheme } from "styled-components"
import Splash from "../components/organisms/Splash"
import ArrowText from "../components/atoms/ArrowText"
import { Link } from 'gatsby';
import { useQueryParam, StringParam } from "use-query-params";
import Illustration from "../assets/xmas-banner.inline.svg"


const SplashText = styled.div`
  min-height: 473px;
  margin-top: 4rem;
  display: flex;
  flex-direction: column-reverse;
  @media (${(props) => props.theme.breakpoints.md}) {
    flex-direction: row;
  }

  .thank-you {
    div:first-child {
      position: relative;
    }
    margin-right: unset;
    flex: 1;
    position: relative;
    @media (${(props) => props.theme.breakpoints.md}) {
      margin-right: 7rem;
    }
  }
  .xmas-image {
    flex: 1;
    margin-bottom: 2rem;
    @media (${(props) => props.theme.breakpoints.md}) {
      margin-bottom: 0;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
`;

const IlloHolder = styled.div`
  margin: 4rem 0 -3.1rem 0;
  @media (${(props) => props.theme.breakpoints.sm}) {
    margin: 4rem 0 -6.1rem 0;
  }
  height: 6rem;
  position: relative;
  .inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    transform: translateX(-50%);
    display: flex;
    svg {
      height: 100%;
      width: auto;
    }
  }
`

const LinkHolder = styled.div`
  @media (${(props) => props.theme.breakpoints.xl}) {
    position: absolute;
    bottom: 0;
    left:0;
  }
  a {
    ${props => props.theme.fluidType( 0)};
  }
`;
function getRandomInt() {
  return Math.floor(Math.random() * 3) + 1;
}

const activitiesObj = {
  'mistletoe' : 'Kissing under the mistletoe',
  'naughty' : "Listing who's naughty or nice",
  'jolly' : 'Having a holly jolly Christmas',
  'tree' : 'Lighting up the tree', 
  'sleigh' : "Riding Santa's sleigh",
  'elves' : 'Surfing with the elves', 
  'presents': 'Ripping wrapping off presents',
  'crackers' : 'Popping crackers and champagne', 
  'dashing' : 'Dashing through the snow ',
  'cricket' : 'Playing backyard cricket', 
}

const styleObj = {
  'impressionism' : 'Impressionist', 
  'renaissance' : 'Renaissance', 
  'pop' : 'Pop Art', 
  'modern' :'Modern Art', 
  'surreal' : 'Surrealist', 
}

const styleArr = [ 
  'impressionism', 
  'renaissance', 
  'pop', 
  'modern', 
  'surreal', 
]

const activitiesArr = [
  'mistletoe',
  'naughty',
  'jolly',
  'tree', 
  'sleigh',
  'elves', 
  'presents',
  'crackers', 
  'dashing',
  'cricket', 
]


const CardTitle = styled.h5`
  font-family: "Lora", serif;
  margin-top: 0;
  margin-bottom: 2rem;
`;


const Card = ({ theme }) => {
    // something like: ?x=123&foo=bar in the URL
    const [style] = useQueryParam("style", StringParam);
    const [activity] = useQueryParam("activity", StringParam);
    const [imgLink, setImgLink] = useState('');
    const [title, setTitle] = useState('');
    const [render, setRender] = useState(false);
    const [renderRedirect, setRenderRedirect] = useState(true);
    const number = getRandomInt();
    useEffect(() => {
      setImgLink(`/images/xmas-images/${style}-${activity}-${number}.png`);
      setTitle(`"${activitiesObj[activity]}" in the ${styleObj[style]} style`);
      setRender(styleArr.includes(style) && activitiesArr.includes(activity));
    }, [style, activity, number]);


    return (
      <>
        <Seo
          title="Christmas Card Generator | Craig Walker"
          thumb={imgLink}
          description="Choose from the options below, and we’ll generate a unique, Christmas-themed artwork with Artificial Intelligence"
        />
        <Splash
          noPadding
          bg={theme.colours.xmas}
          text={
            <>
              <b>Generate real joy</b><br />
              with AI
            </>
          }
        >
          <SplashText>
            <div className="thank-you">
              {render && (
                <div>
                  <CardTitle>{title}</CardTitle>
                  <p style={{marginTop: 0}}>Thank you for generating an artwork with us. Spread the joy and generate some more artwork.</p>
                  <p>This artwork was created with <b><a href="https://github.com/pixray/pixray" target="_blank" rel="noreferrer">Pixray</a></b></p>
                  <ArrowText smallMarginTop>
                    <p><a href={imgLink} download>Download Artwork</a></p>
                  </ArrowText>
                </div>
              )}
              { renderRedirect && (
              <LinkHolder >
                <Link onClick={() => setRenderRedirect(false)} to='/christmas/'>Create another masterpiece</Link>
              </LinkHolder>
              )}
            </div>
            {render && (
              <div className="xmas-image">
                <img src={imgLink} alt=""/>
              </div>
            )}
          </SplashText>
          <IlloHolder>
            <div className="inner">
              <Illustration />
            </div>
          </IlloHolder>
        </Splash>
      </>
    )
}

export default withTheme(Card)